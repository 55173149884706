import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTheme } from '@material-ui/core/styles';
import { ListItem, Typography, Chip, CardMedia } from '@material-ui/core';

import { deleteArticle } from '../redux/actions/article';

import config from 'common/config';
import { ArticleListActions } from './ArticleListActions/ArticleListActions';

import useMashupStyles from '../Mashup/MashupStyles';

const useStyles = makeStyles(({spacing}) => ({
    typeChip: props => ({ ...props.typeChip }),
    meta: props => ({ ...props.meta, marginTop: 0 }),
    message: props => ({ ...props.message, fontSize: '.9rem', fontWeight: 'bold' }),
    listItem: props => ({ ...props.listItem, flexFlow: 'nowrap' }),
    cardContentWrapper: {
        display: 'flex',
        flex: 1
    },
    cardContent: {
        position: 'absolute',
        width: '80%',
        overflow: 'hidden',
        boxSizing: 'border-box',
        paddingLeft: '85px'
    },
    leadText: {
        height: '1.2rem',
        fontSize: '.8rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    media: {
        minWidth: '75px',
        marginTop: 0,
        marginRight: spacing(1),
        height: 0,
        paddingBottom: '75px',
        backgroundColor: '#ddd',
        position: 'relative',
        '&.ms-icon': {
            backgroundSize: 'contain',
            backgroundColor: '#fff'
        }
    }
}));

export const ArticleListCard = ({ article, onSelect }) => {
    const theme = useTheme(),
        dispatch = useDispatch(),
        { ...mashupStyles } = useMashupStyles(theme),
        classes = useStyles(mashupStyles),
        { t } = useTranslation(['dm.articleTypes'], { useSuspense: false }),
        updated = new Date(article.updatedAt),
        dateTime = updated && `${updated.toLocaleDateString()} ${updated.toLocaleTimeString()}`;
        let cardImg = ((article.cardImage || article.headerImage) || '/minstemme-logo_stabla.svg') + '?dimension=167x167';

    if (cardImg && cardImg.indexOf('png') > -1) {
        cardImg += '&mediaType=image/png';
    }

    const handleSelect = id => {
        onSelect && onSelect(id);
    };

    const onDelete = () => {
        dispatch(deleteArticle(article.id));
    };

    const openPreview = () => {
        const url = article.status === 'published'
            ? `${config.get('portalUrl')}/${article.dimu_unique_id}`
            : `/article/preview/${article.id}`;

        window.open(url);
    };

    return (
        <ListItem button component="li" className={classes.listItem}>
            <div className={classes.cardContentWrapper} onClick={() => handleSelect(article.id)}>
                <CardMedia
                    className={`${classes.media}${!(article.cardImage || article.headerImage) ? ' ms-icon' : ''}`}
                    image={cardImg}
                />
                <div className={classes.cardContent}>
                    <div className={classes.meta}>
                        <Chip size="small" variant="outlined" label={t(`dm.articleTypes:${article.type}`)} className={classes.typeChip} />
                        <Chip size="small" variant="outlined" label={t(`dm.article:${article.status}`)} className={classes.typeChip} />
                        {article.createdAt && <Typography component={'span'} >{dateTime} {t('dm.article:by', 'av')} {article.createdBy}</Typography>}
                    </div>
                    <Typography className={classes.message}>
                        {article.title}
                    </Typography>
                    <Typography noWrap className={classes.leadText}>
                        {article.leadText}
                    </Typography>
                </div>
            </div>
            <ArticleListActions onDelete={onDelete} openPreview={openPreview} usedIn={article.used_in_pages} />
        </ListItem>
    );
};
