import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useDeepCompareEffect } from 'react-use';
import { FieldTemplate as DefaultFT } from '@rjsf/material-ui/dist';
import { Trans } from 'react-i18next';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTheme } from '@material-ui/core/styles';
import { List, Paper, Grid, Box, Button, Chip, Input, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import LinkIcon from '@material-ui/icons/Link';
import DoneIcon from '@material-ui/icons/Done';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import useMashupStyles from '../../Mashup/MashupStyles';
import { OwnerWidget } from './widgets/OwnerWidget';
import { PlaceQueryWidget } from './widgets/PlaceQueryWidget';
import { SearchFilterDropdownWidget } from './widgets/SearchFilterDropdownWidget';

import { Accordion } from '@ekultur/accordion';
import {
    ArrayFieldTemplate, ArrayItemTemplate, NoWrapperTemplate, NullTemplate, UniqueKeyFieldTemplate,
    SettingsTemplate, SectionTemplate, BlockArrayFieldTemplate, BlockTemplate, MediaArrayFieldTemplate,
    ImageTemplate, ImageItemTemplate, SvgThumbnailFieldTemplate
} from '@ekultur/ui-schema-components';

import { DmSearchItemTemplate } from './searchItem/SearchItem';

import { MarkdownWidget } from './widgets/MarkdownWidget';

import { requestFilter } from '../../redux/actions/filter';
import { setSubjectsLevels } from '../../redux/actions/selectedSubjectLevel';


const useStyles = makeStyles(({breakpoints, spacing}) => ({
    articleSettings: {
        padding: spacing(1),
    },
    content: {
        '& .image--preview': {
            minHeight: `${spacing(9)}px !important`
        }
    },
    mediaItem: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'wrap',
        '& .MuiTextField-root': {
            width: '100%'
        },
        '& .fieldWrapper': {
            width: 'calc(100% - 120px)',
            '& label + .MuiInput-formControl': {
                marginTop: spacing(1.5)
            },
            '& .anchor_desc': {
                flex: '0 0 100%',
                marginTop: spacing(1)
            },
            '& .link_btn': {
                marginTop: spacing(1),
                marginRight: 'auto',
                '& + .ok': {
                    marginTop: spacing(1),
                    marginRight: '100%'
                }
            }
        },
        '& .image--preview': {
            position: 'relative',
            minWidth: spacing(9),
            marginRight: '.5em',
            placeContent: 'center',
            background: '#423f3c',
            '&.svg': {
                background: 'white'
            },
            '& img': {
                position: 'absolute',
                top: 0,
                bottom: 0,
                maxWidth: '100%',
                maxHeight: '100%',
                width: 'auto',
                height: 'auto',
                margin: 'auto'
            }
        }
    },
    tagWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > div': {
            marginTop: spacing(1),
            marginRight: spacing(1)
        },
        '& .MuiChip-label': {
            overflow: 'visible'
        }
    },
    chip: props => ({
        ...props.typeChip,
        marginBottom: spacing(.5)
    })
}));

const ContentTemplate = props => {
    const classes = useStyles(),
        settingObjects = ['promoted', 'level', 'subject', 'category', 'curriculum', 'cardImage'],
        settingsElmts = props.properties.filter(el => settingObjects.includes(el.name));

    return (
        <List className={classes.content} disablePadding={true}>
            {!!settingsElmts.length && ArticleSettingsTemplate(settingsElmts)}
            {props.properties.map(el => {
                if (!settingObjects.includes(el.name)) {
                    return el.content
                }
                return null;
            })}
        </List>
    );
};

const DmArrayFieldTemplate = props => {
    const { formContext, idSchema, items } = props,
        [removeMedia, setRemoveMedia] = useState(null),
        toolbarClass = useStyles().toolbar;

    const changeHandler = idx => {
        setRemoveMedia(idx);
    };

    const onAddObject = () => {
        formContext.mediaLib.open(idSchema.$id, items.map(x => x.children.props.formData), changeHandler, true);
    };

    useEffect(() => {
        if (removeMedia !== null) {
            items[removeMedia].onDropIndexClick(removeMedia)();
            setRemoveMedia(null);
        }
    }, [removeMedia]);

    return (
        <>
            {items.map(el => ArrayItemTemplate(el, false, formContext.mediaLib))}
            {props.canAdd &&
                <div className={toolbarClass}>
                    <Button
                        variant="contained"
                        className="array-item-add"
                        onClick={() => onAddObject()}
                        disabled={props.disabled || props.readonly}
                    >
                        <AddIcon />
                        {props.uiSchema['ui:options'] && props.uiSchema['ui:options'].addButtonLabel}
                    </Button>
                    <Button
                        variant="contained"
                        className="array-item-add"
                        onClick={props.onAddClick}
                        disabled={props.disabled || props.readonly}
                    >
                        <AddIcon />
                        {props.uiSchema['ui:options'] && props.uiSchema['ui:options'].addSearchLabel}
                    </Button>
                </div>
            }
        </>
    );
};

export const RelatedArrayFieldTemplate = props => {
    const { formContext, idSchema, schema, items } = props,
        [removeMedia, setRemoveMedia] = useState(null),
        toolbarClass = useStyles().toolbar;

    const changeHandler = idx => {
        setRemoveMedia(idx);
    };

    const onAdd = () => {
        if (formContext && formContext.related) {
            formContext.related.open(schema.items.properties.type.default, idSchema.$id, items.map(x => x.children.props.formData), changeHandler);
        }
    };

    useEffect(() => {
        if (removeMedia !== null) {
            items[removeMedia].onDropIndexClick(removeMedia)();
            setRemoveMedia(null);
        }
    }, [removeMedia]);

    return (
        <>
            {items.map(el => ArrayItemTemplate(el, false, formContext && formContext.related))}
            {props.canAdd && (
                <div className={toolbarClass}>
                    {schema.items.properties && schema.items.properties.type.enum
                        ? schema.items.properties.type.enum.map((opt, i) => {
                            return (
                                <Button key={opt} variant="contained" className="array-item-add" onClick={() => onAdd()}>
                                    <AddIcon />
                                    {schema.items.properties.type.enumNames ? schema.items.properties.type.enumNames[i] : opt}
                                </Button>
                            )
                        })
                        : <Button
                            variant="contained"
                            className="array-item-add"
                            onClick={() => onAdd()}
                            disabled={props.disabled || props.readonly}
                        >
                            <AddIcon />
                            {props.uiSchema['ui:options'] && props.uiSchema['ui:options'].addButtonLabel}
                        </Button>
                    }
                </div>
            )}
        </>
    );
};

const RelatedArtImageItemTemplate = ({ props, classes, type, formContext = null }) => {
    const sEl = props.find(x => x.name === 'settings'),
        cardImg = props.find(x => x.name === 'cardImage'),
        headerImg = props.find(x => x.name === 'headerImage'),
        url = ((cardImg && cardImg.content.props.formData) && cardImg) || headerImg,
        source = props.find(x => x.name === 'source'),
        mt = props.find(x => x.name === 'mimetype'),
        svg = mt && mt.content.props.formData === 'image/svg+xml';

    let urlParams = '?dimension=167x167';

    if (!svg && mt && mt.content && mt.content.props && mt.content.props.formData && mt.content.props.formData.indexOf('png') > -1) {
        urlParams += '&mediaType=image/png';
    }

    return (
        <div key={url.content.props.formData} className={`${classes.mediaItem} ${type === 'DM' ? classes.searchItem : ''} greyBox`}>
            <div  className={`image--preview${svg ? ' svg' : ''}`} >
                {svg
                    ? source.content
                    : <img key={url.content.props.idSchema.$id} src={`${url.content.props.formData}${urlParams}`} alt="thumbnail" />
                }
            </div>
            <div className="fieldWrapper">
                {props.map(el => {
                    if (['title'].includes(el.name)) {
                        return el.content;
                    }
                })}
            </div>
            {sEl && sEl.content}
        </div>
    );
};

const RelatedArtImageTemplate = props => {
    const { properties, formContext, schema } = props,
        classes = useStyles();

    return <RelatedArtImageItemTemplate props={properties} classes={classes} type={props.formData.type} formContext={formContext} />;
};

const LinkArrayFieldTemplate = props => {
    const {items, schema, onAddClick} = props,
        toolbarClass = useStyles().toolbar;

    const onAdd = type => {
        schema.items.properties.type.default = type;
        onAddClick();
    };

    return (
        <>
            <h5 className="headerBlock_links_title"><Trans ns="dm.article" i18nKey="owner.headerBlock.links_title">Lenker</Trans></h5>
            {items.map(el => ArrayItemTemplate(el))}
            {props.canAdd && (
                <div className={toolbarClass}>
                    <Button
                        variant="contained"
                        className="array-item-add"
                        onClick={() => onAdd('article__bodytext')}
                        disabled={props.disabled || props.readonly}
                    >
                        <AddIcon/>
                        {props.uiSchema['ui:options'] && props.uiSchema['ui:options'].addInternalLinkButtonLabel}
                    </Button>
                    <Button
                        variant="contained"
                        className="array-item-add"
                        onClick={() => onAdd('link')}
                        disabled={props.disabled || props.readonly}
                    >
                        <AddIcon/>
                        {props.uiSchema['ui:options'] && props.uiSchema['ui:options'].addLinkButtonLabel}
                    </Button>
                </div>
            )}
        </>
    );
};

const LinkItemTemplate = props => {
    const [expanded, setExpanded] = useState(false),
        classes = useStyles();

    return (
        <div key={props.index} className={`${expanded ? classes.mediaItem : classes.searchItem} greyBox`}>
            {!expanded && (
                <div className="titleWrapper" onClick={() => setExpanded(true)}>
                    <LinkIcon/>
                    {props.properties.map(el => {
                        if (el.name === 'title') {
                            return <h5 key="title">{el.content.props.formData}</h5>;
                        }
                    })}
                </div>
            )}
            {expanded && (
                <div className="fieldWrapper">
                    {props.properties.map(el => {
                        if (!['type', 'settings', '_editor'].includes(el.name)) {
                            return el.content;
                        }
                    })}
                    <Button
                        className="ok"
                        onClick={() => setExpanded(false)}
                        color="secondary">
                        {props.uiSchema['ui:options'] && props.uiSchema['ui:options'].closeButtonLabel || 'Lukk'}
                    </Button>
                </div>
            )}
        </div>
    );
};

const SearchTemplate = props => {
    const classes = useStyles();

    if (props.formData.type === 'DM') {
        return <ImageItemTemplate props={props.properties} classes={classes} type={props.formData.type} formContext={props.formContext}/>;
    } else {
        return <DmSearchItemTemplate props={props} classes={classes}/>;
    }
};

const ReadOnlyValueFieldTemplate = props => {
    const theme = useTheme(),
        {...mashupStyles} = useMashupStyles(theme),
        classes = useStyles(mashupStyles);

    return (
        props.children.props.children.map(el => {
            return (
                el && <Chip key={el.props.formData} className={classes.chip} size="small" variant="outlined" label={el.props.formData}/>
            );
        })
    );
};

const ArticleSettingsTemplate = props => {
    const [expanded, setExpanded] = useState(false);

    return (
        <Accordion title="Innstillinger" expanded={expanded} onToggle={() => setExpanded(!expanded)}>
            {props.map(el => {
                return el.content;
            })}
        </Accordion>
    );
};

const ArticleSettingsArrayFieldTemplate = props => {
    const classes = useStyles(),
        [tag, setTag] = useState();

    const onChange = ({ target: { value } }) => {
        setTag(value);
    }

    const addClick = value => {
        props.schema.items.properties.tag.default = value;
        setTag('');
        props.onAddClick();
    }

    return (
        <List className={classes.articleSettings}>
            <Grid item={true} xs>
                <Box mb={2}>
                    <Paper elevation={2}>
                        <Box p={2}>
                            {props.title && <h4>{props.title}</h4>}
                            {props.idSchema.tag
                                ? (
                                    <div className={classes.tagWrapper}>
                                        {props.items.map(el => {
                                            return <Chip label={el.children.props.formData.tag} onDelete={el.onDropIndexClick(el.index)} key={el.index}/>
                                        })}
                                    </div>
                                ) : props.items.map(el => ArrayItemTemplate(el))
                            }
                            {props.canAdd && props.idSchema.tag && (
                                <>
                                    <Input value={tag} onChange={onChange}/>
                                    <IconButton
                                        aria-label={props.uiSchema['ui:options'].addButtonLabel}
                                        disabled={!tag || tag.length < 2}
                                        onClick={() => addClick(tag)}
                                        style={!tag || tag.length < 2 ? {} : {color: 'green'}}
                                    >
                                        <DoneIcon/>
                                    </IconButton>
                                </>
                            )}
                        </Box>
                    </Paper>
                </Box>
            </Grid>
        </List>
    );
};

const ArticleSettingsItemTemplate = props => {
    const {properties} = props;

    return (
        <>
            {props.title && <span key={props.title}>{props.title}</span>}
            {properties.map(el => {
                if (props.idSchema.$id.includes('tags')) {
                    if (el.name === 'tag') {
                        return el.content;
                    }
                } else if (el.name === 'title_expanded') {
                    return el.content;
                }
            })}
        </>
    );
};

const LevelWidget = props => {
    const dispatch = useDispatch(),
        { value, onChange } = props,
        { level_data } = useSelector(state => state.level);

    useEffect(() => {
        dispatch(requestFilter('level'));
    }, [dispatch]);
    
    useEffect(() => {
        if (value) {
            dispatch(setSubjectsLevels('levels', JSON.parse(value)));
        }
    }, [dispatch, value]);

    // useDeepCompareEffect(() => {
    //     dispatch(setSubjectsLevels('level', level_data));
    // }, [level_data, dispatch]);

    const onChangeLevel = (event, newValue) => {
        onChange && onChange(JSON.stringify(newValue));
    };

    const defaultPropsLevel = {
        options: level_data,
        getOptionSelected: (option, level) => option.id === level.id,
        getOptionLabel: (option) => option.title,
    };

    return (
        <Autocomplete
            multiple
            {...defaultPropsLevel}
            id="controlled-trinn"
            value={value ? JSON.parse(value) : []}
            onChange={onChangeLevel}
            renderInput={(params) => (<TextField {...params} label="Trinn" margin="normal"/>)}
        />
    );
};

const SubjectWidget = props => {
    const dispatch = useDispatch(),
        { value, onChange } = props,
        { subject_data } = useSelector(state => state.subject);

    useEffect(() => {
        dispatch(requestFilter('subject'));
    }, [dispatch]);
    
    useEffect(() => {
        if (value) {
            dispatch(setSubjectsLevels('subjects', JSON.parse(value)));
        }
    }, [dispatch, value]);

    const onChangeSubject = (event, newValue) => {
        onChange && onChange(JSON.stringify(newValue));
    };

    const defaultPropsLevel = {
        options: subject_data,
        getOptionSelected: (option, level) => option.id === level.id,
        getOptionLabel: (option) => option.title,
    };

    return (
        <Autocomplete
            multiple
            {...defaultPropsLevel}
            id="controlled-fag"
            value={value ? JSON.parse(value) : []}
            onChange={onChangeSubject}
            renderInput={(params) => (<TextField {...params} label="Fag" margin="normal"/>)}
        />
    );
};

const CategoryWidget = props => {
    const dispatch = useDispatch(),
        { value, onChange } = props,
        { category_data } = useSelector(state => state.category);

    useEffect(() => {
        dispatch(requestFilter('category'));
    }, [dispatch]);

    const onChangeCategory = (event, newValue) => {
        onChange && onChange(JSON.stringify(newValue));
    };

    const reformattedCategory = category_data.reduce((akku, row) => {
        const categoryRows = [];
        if (row.children.length > 0) {
            row.children.map((child) =>
                categoryRows.push({
                    title: child.title,
                    children: child.children,
                    id: child.id,
                    titleParent: row.title,
                    type: child.type
                }));
        } else if (row.children.length === 0) {
            categoryRows.push({...row, titleParent: 'Andre tema'});
        }
        akku = akku.concat(categoryRows);
        return akku;
    }, []);

    const defaultPropsLevel = {
        options: reformattedCategory,
        getOptionSelected: (option, level) => option.id === level.id,
        getOptionLabel: (option) => option.title,
    };

    return (
        <Autocomplete
            multiple
            {...defaultPropsLevel}
            groupBy={(option) => option.titleParent}
            id="controlled-tema"
            value={value ? JSON.parse(value) : []}
            onChange={onChangeCategory}
            renderInput={(params) => (<TextField {...params} label="Tema" margin="normal"/>)}
        />
    );
};

const CurriculumWidget = props => {
    const dispatch = useDispatch(),
        { value, onChange } = props,
        { curriculum_data } = useSelector(state => state.curriculum),
        { subjects, levels } = useSelector(state => state.selectedSubjectLevel),
        [selected, setSelected] = useState([]);

    useEffect(() => {
        dispatch(requestFilter('curriculum'));
    }, [dispatch]);
    
    useEffect(() => {
        if (value) {
            setSelected(JSON.parse(value));
        }
    }, [value]);

    const onChangeCurriculum = (subject, newValue) => {
        const upd = newValue.map(c => ({ id: c.id, sid: c.subjectId, lid: c.levelId })),
            curr = selected.filter(s => s.sid !== subject.id);
        onChange && onChange(JSON.stringify(curr.concat(upd)));
    };

    const reducedCurriculum = subject => {
        if (!subjects.length || !levels.length || !curriculum_data.length) {
            return [];
        }

        const curriculum = curriculum_data.find(c => c.id === subject.id);

        if (!curriculum) {
            return [];
        }

        return curriculum.children.reduce((akku, row) => {
            const lvl = levels.find(l => l.id === row.id),
                rows = [];
            
            if (!lvl) {
                return akku;
            }

            if (row.children.length > 0) {
                row.children.map((child) => {
                    rows.push({
                        title: child.description,
                        children: child.children,
                        id: child.id,
                        levelTitle: lvl.title,
                        levelId: lvl.id,
                        subjectId: subject.id,
                        subjectTitle: subject.title
                    })
                });
            }

            return akku.concat(rows);
        }, []);
    };

    const setOptions = subject => {
        return {
            options: reducedCurriculum(subject),
            getOptionSelected: (option, c) => option.id === c.id,
            getOptionLabel: (option) => option.title
        };
    };

    const setSelectedOption = subject => {
        if (!selected) {
            return [];
        }

        const c = reducedCurriculum(subject),
            sel = selected.map(s => s.id),
            sbj = c.filter(s => sel.includes(s.id));

        return sbj || [];
    }

    return (curriculum_data
        ? subjects.map(s => (
            <Fragment key={s.title}>
                <Autocomplete
                    multiple
                    {...setOptions(s)}
                    groupBy={(option) => option.levelTitle}
                    id="controlled-tema"
                    value={setSelectedOption(s)}
                    onChange={(e, v) => onChangeCurriculum(s, v)}
                    noOptionsText="Ingen kompetansemål under valgt(-e) trinn!"
                    renderInput={(params) => (<TextField {...params} label={`Kompetansemål - ${s.title}`} margin="normal" />)}
                />
            </Fragment>))
        : null
    );
};

const AnchorWidget = props => {
    const {formContext, id, value, onChange} = props,
        [anchorMode, setAnchorMode] = useState(false),
        [anchorId, setanchorId] = useState(value);

    const anchorHandler = () => {
        const changeHandler = id => {
            setanchorId(id);
        };

        if (!anchorMode) {
            formContext.onAnchorMode(id, value, changeHandler);
        } else {
            formContext.onAnchorMode(false);
        }

        setAnchorMode(!anchorMode);
    };

    useEffect(() => {
        if (anchorId !== value) {
            onChange(anchorId);
        }
    }, [anchorId]);

    return (
        <Fragment key={id}>
            {value}
            <p className="anchor_desc">
                {!anchorMode
                    ? <Trans ns="dm.article" i18nKey="owner.headerblock.logoDesc.link_desc">Klikk «Generer lenke» og deretter en innholdsblokk i forhåndsvisningen for å generere lenke:</Trans>
                    : <Trans ns="dm.article" i18nKey="owner.headerblock.logoDesc.link_descDone">Klikk en innholdsblokk i forhåndsvisningen for å generere lenke, deretter «Ferdig»:</Trans>
                }
            </p>
            <Button className="link_btn" variant="contained" color="secondary" onClick={anchorHandler}>
                {anchorMode
                    ? <Trans ns="dm.article" i18nKey="owner.headerblock.logoDesc.link_generateDone">Ferdig</Trans>
                    : <Trans ns="dm.article" i18nKey="owner.headerblock.logoDesc.link_generate">Generer lenke</Trans>
                }
            </Button>
        </Fragment>
    );
};

const mediaListUiSchema = {
    'ui:ArrayFieldTemplate': MediaArrayFieldTemplate,
    'ui:options': {
        addButtonLabel: <Trans ns="dm.article" i18nKey="add_image">Finn media</Trans>
    },
    items: {
        'ui:ObjectFieldTemplate': ImageTemplate,
        source: {'ui:FieldTemplate': SvgThumbnailFieldTemplate},
        settings: {
            'ui:ObjectFieldTemplate': SettingsTemplate
        },
        licenses: {
            'ui:ArrayFieldTemplate': ArrayFieldTemplate,
            'ui:options': {addButtonLabel: <Trans ns="dm.article" i18nKey="add_license">Legg til lisens</Trans>},
            items: {
                'ui:ObjectFieldTemplate': NoWrapperTemplate,
            }
        }
    }
};

export default {
    content: {
        'ui:ObjectFieldTemplate': ContentTemplate,
        page_type: {
            'ui:widget': 'hidden'
        },
        cardImage: {
            resources: {
                // 'ui:ObjectFieldTemplate': NoWrapperTemplate,
                mediaList: mediaListUiSchema
            }
        },
        level: {
            'ui:widget': LevelWidget
            // 'ui:ArrayFieldTemplate': ArrayFieldTemplate,
            // items: {'ui:widget': LevelWidget}
        },
        subject: {
            'ui:widget': SubjectWidget
        },
        category: {
            'ui:widget': CategoryWidget,
        },
        curriculum: {
            'ui:widget': CurriculumWidget,
        },
        local_belonging: {
            'ui:ArrayFieldTemplate': ArticleSettingsArrayFieldTemplate,
            items: {
                'ui:ObjectFieldTemplate': ArticleSettingsItemTemplate,
                'ui:FieldTemplate': DefaultFT,
                title_expanded: {
                    'ui:widget': PlaceQueryWidget
                }
            },
            'ui:options': {
                addable: false,
                removable: false,
                orderable: false
            }
        },
        tags: {
            'ui:ArrayFieldTemplate': ArticleSettingsArrayFieldTemplate,
            items: {
                'ui:ObjectFieldTemplate': ArticleSettingsItemTemplate,
            },
            'ui:options': {
                addButtonLabel: <Trans ns="dm.article" i18nKey="add_topic">Legg til nøkkelord</Trans>,
                orderable: false
            }
        },
        sections: {
            'ui:ArrayFieldTemplate': ArrayFieldTemplate,
            items: {
                'ui:ObjectFieldTemplate': SectionTemplate,
                _editor: {
                    'ui:ObjectFieldTemplate': NoWrapperTemplate,
                    _collapse: {
                        'ui:widget': 'hidden'
                    }
                },
                blocks: {
                    'ui:ArrayFieldTemplate': BlockArrayFieldTemplate,
                    items: {
                        'ui:ObjectFieldTemplate': BlockTemplate,
                        _editor: {
                            'ui:ObjectFieldTemplate': NoWrapperTemplate,
                            _collapse: {
                                'ui:widget': 'hidden'
                            }
                        },
                        _unique_key: {'ui:FieldTemplate': UniqueKeyFieldTemplate},
                        settings: {'ui:ObjectFieldTemplate': SettingsTemplate},
                        type: {'ui:FieldTemplate': NullTemplate},
                        title: {'ui:FieldTemplate': DefaultFT},
                        author: {'ui:FieldTemplate': DefaultFT},
                        authorUrl: {'ui:FieldTemplate': DefaultFT},
                        leadtext: {
                            'ui:FieldTemplate': DefaultFT,
                            'ui:widget': MarkdownWidget
                        },
                        'ui:options': {
                            addable: false,
                            removable: false,
                        },
                        bodytext: {
                            'ui:FieldTemplate': DefaultFT,
                            'ui:widget': MarkdownWidget
                        },
                        maxListItems: {
                            'ui:FieldTemplate': DefaultFT,
                            'ui:widget': 'updown'
                        },
                        showMoreLabel: {'ui:FieldTemplate': DefaultFT},
                        searchUrl: {'ui:FieldTemplate': DefaultFT},
                        resources: {
                            'ui:ObjectFieldTemplate': NoWrapperTemplate,
                            mediaList: mediaListUiSchema,
                            backgroundImage: mediaListUiSchema,
                            relatedList: {
                                'ui:ArrayFieldTemplate': RelatedArrayFieldTemplate,
                                items: {
                                    'ui:ObjectFieldTemplate': RelatedArtImageTemplate,
                                    title: {'ui:FieldTemplate': DefaultFT},
                                    id: {'ui:FieldTemplate': ReadOnlyValueFieldTemplate}
                                }
                            },
                            entryList: {
                                'ui:ArrayFieldTemplate': DmArrayFieldTemplate,
                                'ui:options': {
                                    addButtonLabel: <Trans ns="dm.article" i18nKey="add_object">Finn objekt</Trans>,
                                    addSearchLabel: <Trans ns="dm.article" i18nKey="add_search">Legg til søk</Trans>
                                },
                                items: {
                                    'ui:ObjectFieldTemplate': SearchTemplate,
                                    objectId: {'ui:FieldTemplate': ReadOnlyValueFieldTemplate},
                                    artifactType: {'ui:FieldTemplate': ReadOnlyValueFieldTemplate},
                                    filter: {
                                        owner: {'ui:widget': OwnerWidget},
                                        rows: {
                                            'ui:widget': 'updown'
                                        },
                                        type: {'ui:widget': SearchFilterDropdownWidget},
                                        sort: {'ui:widget': SearchFilterDropdownWidget},
                                        sort_order: {'ui:widget': SearchFilterDropdownWidget}
                                    },
                                    settings: {
                                        'ui:ObjectFieldTemplate': SettingsTemplate
                                    },
                                    title: {'ui:FieldTemplate': DefaultFT},
                                    producer: {'ui:FieldTemplate': DefaultFT},
                                    query: {'ui:FieldTemplate': DefaultFT}
                                }
                            },
                            logo: {
                                'ui:ArrayFieldTemplate': MediaArrayFieldTemplate,
                                'ui:options': {
                                    addButtonLabel: <Trans ns="dm.article" i18nKey="owner.headerBlock.add_logo">Finn logo</Trans>
                                },
                                items: {
                                    'ui:ObjectFieldTemplate': ImageTemplate,
                                    licenses: mediaListUiSchema.items.licenses,
                                    source: {'ui:FieldTemplate': SvgThumbnailFieldTemplate}
                                }
                            },
                            linkList: {
                                'ui:ArrayFieldTemplate': LinkArrayFieldTemplate,
                                'ui:options': {
                                    addInternalLinkButtonLabel: <Trans ns="dm.article" i18nKey="owner.headerBlock.add_internalLink">Lenke til innhold på siden</Trans>,
                                    addLinkButtonLabel: <Trans ns="dm.article" i18nKey="owner.headerBlock.add_link">Legg til URL eller epost</Trans>
                                },
                                items: {
                                    'ui:ObjectFieldTemplate': LinkItemTemplate,
                                    'ui:options': { closeButtonLabel: <Trans ns="dm.common" i18nKey="close">Lukk</Trans> },
                                    type: {'ui:widget': 'hidden'},
                                    _unique_key: {'ui:widget': AnchorWidget}
                                }
                            }
                        },
                        presentation: {
                            'ui:FieldTemplate': DefaultFT,
                            'ui:widget': 'textarea'
                        },
                        homepage: {'ui:FieldTemplate': DefaultFT}
                    }
                }
            },
            'ui:options': {
                // addable: false,
                // removable: false,
                orderable: false
            }
        }
    }
};
