import React, { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Form from '@rjsf/material-ui';
import { Box, Button, Dialog, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteForever';

import schema from '../Article/schema/resource/ResourceSchema';
import { requestFilter, addResource, updateResource, deleteResource } from '../redux/actions/filter';
import { ConfirmDialog } from '../Article/ArticleListActions/ConfirmDialog';

const useStyles = makeStyles(({ spacing }) => ({
    curriculum: {
        display: 'flex',
        flexFlow: 'wrap',
        margin: '0 1em',
        '& .select': {
            flex: '1 1 47%',
            '&:first-child': {
                margin: '0 1em 0 0',
            }
        },
        '& .list': {
            flex: '1 0 100%'
        }
    },
    dialog: {
        '& button:first-of-type': {
            marginRight: '1em'
        }
    },
    addBtn: {
        marginTop: '1em',
        marginLeft: '1em',
        marginBottom: '1em',
    }
}));

export default function SubjectLevelEditor({ match }) {
    const updateData = (state, action) => {
        return action.type === 'level' ? level_data : subject_data;
    }

    const dispatch = useDispatch(),
        classes = useStyles(),
        { user } = useSelector(state => state.header),
        { subject_data, updated: subject_updated } = useSelector(state => state.subject),
        { level_data, updated: level_updated } = useSelector(state => state.level),
        [data, setData] = useReducer(updateData, null),
        [type, setType] = useState(),
        [selected, setSelected] = useState(false),
        [confirmOpen, setConfirmOpen] = useState(false);

    useEffect(() => {
        if (type) {
            dispatch(requestFilter(type));
        }
    }, [dispatch, type]);

    useEffect(() => {
        type && setData({type: type});
    }, [type, subject_updated, level_updated]);

    useEffect(() => {
        setType(match.path.replace('/', ''));
    }, [match.path]);

    const onSave = form => {
        dispatch(updateResource(user, type, form.formData));
        console.log(`update resource with id ${form.formData.id}. New value: ${form.formData.title}`);
        setSelected(false);
    };

    const onDeleteOpen = c => {
        setConfirmOpen(true);
        setSelected(c);
    };

    const onDeleteConfirm = () => {
        selected && dispatch(deleteResource(type, selected.id));
        setConfirmOpen(false);
        setSelected(false);
    };

    const onDeleteCancel = () => {
        setConfirmOpen(false);
        setSelected(false);
    };

    const onAdd = form => {
        dispatch(addResource(user, type, form.formData));
        setSelected(false);
    };

    const renderList = () => {
        return (
            <List className="list">
                {data.map(c => {
                    return (
                        <ListItem key={c.id} onClick={() => setSelected(c)}>
                            <ListItemIcon>
                                <EditIcon />
                            </ListItemIcon>
                            <ListItemText primary={c.title} />
                            <ListItemIcon onClick={() => onDeleteOpen(c)}>
                                <DeleteIcon />
                            </ListItemIcon>
                        </ListItem>
                    )
                })}
            </List>
        );
    };

    return (
        <div className={classes.curriculum}>
            <Button className={classes.addBtn} variant="contained" color="primary" onClick={() => setSelected('new')}>
                {`Opprett nytt ${type === 'level' ? 'trinn' : 'fag'}`}
            </Button>
            {data && renderList()}
            <Dialog
                open={!!selected}
                className={classes.dialog}
            >
                {selected && (
                    <Box m={3}>
                        <Form
                            schema={schema[type]}
                            formData={selected}
                            onSubmit={selected === 'new' ? onAdd : onSave}
                        >
                            <Button type="submit" variant="contained" color="primary" className={classes.button}>Lagre</Button>
                            <Button type="submit" variant="contained" color="secondary" className={classes.button} onClick={() => setSelected(null)}>Avbryt</Button>
                        </Form>
                    </Box>
                )}
            </Dialog>
            <ConfirmDialog
                title={`Slett ${type === 'level' ? 'trinn' : 'fag'}`}
                confirmLabel="Ja"
                cancelLabel="Nei"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={onDeleteConfirm}
                onCancel={onDeleteCancel}
            >
                Er du sikker?
            </ConfirmDialog>
        </div>
    );
}