import React, { useRef } from 'react';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { Button } from '@material-ui/core';


const FileSelector = ({ uploadHandler }) => {
    const hiddenFileInput = useRef(null);

    const handleClick = e => {
        hiddenFileInput.current.click();
    };

    const handleChange = (e) => {
        uploadHandler(e.target.files)
        /*const files = e.target.files;
        if (files && files[0]) {
            uploadHandler(files[0]);
        }*/
    };

    return (
        <>
            <Button variant="contained" startIcon={<CloudUploadIcon />} color="primary" onClick={handleClick}>
                Last opp bilde/dokument
            </Button>
            <input
                id="file-upload"
                type="file"
                onChange={handleChange}
                ref={hiddenFileInput}
                style={{ display: 'none' }}
            />
        </>
    );
};

export default FileSelector;
