import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Form from '@rjsf/material-ui';
import { Box, Button, Dialog, List, ListItem, ListItemIcon, ListItemText, TextField, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { requestFilter, addCurriculum, updateCurriculum, deleteCurriculum } from '../redux/actions/filter';
import { ConfirmDialog } from '../Article/ArticleListActions/ConfirmDialog';
import curriculumSchema from '../Article/schema/resource/CurriculumSchema';

const useStyles = makeStyles(({ spacing }) => ({
    curriculum: {
        display: 'flex',
        flexFlow: 'wrap',
        margin: '0 1em',
        '& .select': {
            flex: '1 1 47%',
            '&:first-child': {
                margin: '0 1em 0 0',
            }
        },
        '& .list': {
            flex: '1 0 100%'
        }
    },
    dialog: {
        '& button:first-of-type': {
            marginRight: '1em'
        }
    },
    addBtn: {
        marginTop: '1em',
        marginLeft: '1em',
        marginBottom: '1em',
    }
}));

export default function CurriculumEditor() {
    const dispatch = useDispatch(),
        classes = useStyles(),
        { user } = useSelector(state => state.header),
        { curriculum_data, updated } = useSelector(state => state.curriculum),
        [subject, setSubject] = useState(),
        [level, setLevel] = useState(),
        [selected, setSelected] = useState(false),
        [confirmOpen, setConfirmOpen] = useState(false),
        [curriculum, setCurriculum] = useState([]),
        { subject_data } = useSelector(state => state.subject),
        { level_data } = useSelector(state => state.level);

    const reducedCurriculum = () => {
        if (!subject_data.length || !level_data.length || !curriculum_data.length) {
            return [];
        }

        const findCurriculum = () => {
            const cd = curriculum_data.find(c => c.id === subject.id),
                r = cd && cd.children && cd.children.find(l => l.id === level.id);

            return r || [];
        }

        const clm = findCurriculum();

        return clm && clm.children || [];
    };

    useEffect(() => {
        dispatch(requestFilter('level'));
        dispatch(requestFilter('subject'));
        dispatch(requestFilter('curriculum'));
    }, [dispatch]);

    useEffect(() => {
        if (subject && level) {
            setCurriculum(reducedCurriculum());
        }
    }, [subject, level, updated]);

    const defaultProps = type => {
        return {
            options: type === 'level' ? level_data : subject_data,
            getOptionSelected: (option, lvl) => option.id === lvl.id,
            getOptionLabel: (option) => option.title
        }
    };

    const onSave = form => {
        dispatch(updateCurriculum(user, subject.id, level.id, form.formData));
        console.log(`update curriculum with id ${form.formData.id}. New value: ${form.formData.description}`);
        setSelected(false);
    };

    const onDeleteOpen = c => {
        setConfirmOpen(true);
        setSelected(c);
    };

    const onDeleteConfirm = () => {
        selected && dispatch(deleteCurriculum(subject.id, level.id, selected.id));
        setConfirmOpen(false);
        setSelected(false);
    };

    const onDeleteCancel = () => {
        setConfirmOpen(false);
        setSelected(false);
    };

    const onAdd = form => {
        dispatch(addCurriculum(user, subject.id, level.id, form.formData));
        setSelected(false);
    }

    return (
        <div className={classes.curriculum}>
            <Autocomplete
                {...defaultProps('subject')}
                id="controlled-subject"
                className="select"
                onChange={(e, v) => setSubject(v)}
                renderInput={(params) => (<TextField {...params} label="Fag" margin="normal" />)}
            />
            <Autocomplete
                {...defaultProps('level')}
                id="controlled-level"
                className="select"
                onChange={(e, v) => setLevel(v)}
                renderInput={(params) => (<TextField {...params} label="Trinn" margin="normal" />)}
            />
            {(level && subject) && (
                <Button className={classes.addBtn} variant="contained" color="primary" onClick={() => setSelected('new')}>
                    Opprett nytt kompetansemål
                </Button>
            )}
            {(curriculum_data && level && subject)
                ? (
                <List className="list">
                    {curriculum.map(c => {
                        return (
                            <ListItem key={c.id} onClick={() => setSelected(c)}>
                                <ListItemIcon>
                                    <EditIcon />
                                </ListItemIcon>
                                <ListItemText primary={c.description} />
                                <ListItemIcon onClick={() => onDeleteOpen(c)}>
                                    <DeleteIcon />
                                </ListItemIcon>
                            </ListItem>
                        )
                    }
                    )}
                    </List>
                ) : (
                    <Typography component="h2">Velg fag og trinn over for å redigere kompetansemål</Typography>
                )
            }
            <Dialog
                open={!!selected}
                className={classes.dialog}
            >
                {selected && (
                    <Box m={3}>
                        <Form
                            schema={curriculumSchema}
                            formData={selected}
                            // onChange={form => setEdit(form.formData)}
                            onSubmit={selected === 'new' ? onAdd : onSave}
                        >
                            <Button type="submit" variant="contained" color="primary" className={classes.button}>Lagre</Button>
                            <Button type="submit" variant="contained" color="secondary" className={classes.button} onClick={() => setSelected(null)}>Avbryt</Button>
                        </Form>
                    </Box>
                )}
            </Dialog>
            <ConfirmDialog
                title="Slett kompetansemål"
                confirmLabel="Ja"
                cancelLabel="Nei"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={onDeleteConfirm}
                onCancel={onDeleteCancel}
            >
                Er du sikker?
            </ConfirmDialog>
        </div>
    );
}