export default {
    subject: {
        type: 'object',
        properties: {
            id: {
                type: 'integer',
                readOnly: true
            },
            title: {
                title: 'Fag',
                type: 'string'
            }
        }
    },
    level: {
        type: 'object',
        properties: {
            id: {
                type: 'integer',
                readOnly: true
            },
            title: {
                title: 'Trinn',
                type: 'string'
            }
        }
    },
    category: {
        type: 'object',
        properties: {
            id: {
                type: 'integer',
                readOnly: true
            },
            title: {
                title: 'Tema',
                type: 'string'
            },
            source: {
                type: 'integer',
                title: 'Undertema av id'
            }
        }
    }
};
