import page from './pageSchema';
import assignment from './assignmentSchema';
import contribution from './contributionSchema';

export default {
    type: 'object',
    title: 'Relaterte artikler',
    properties: {
        type: { enum: ['article__related'] },
        _editor: {
            '$ref': '#/definitions/_editor'
        },
        blocks: {
            type: 'array',
            maxItems: 3,
            title: null,
            items: {
                type: 'object',
                required: ['type'],
                properties: {
                    type: {
                        type: 'string',
                        readOnly: true,
                        enum: ['related__resources', 'related__assignments', 'related__contributions'],
                        enumNames: ['ressurser', 'oppgaver', 'innsendte bidrag'],
                        default: 'related__resources'
                    }
                },
                dependencies: {
                    type: {
                        oneOf: [
                            {
                                type: 'object',
                                required: ['type'],
                                title: 'Ressurser',
                                properties: {
                                    type: { enum: ['related__resources'] },
                                    resources: {
                                        type: 'object',
                                        properties: {
                                            relatedList: {
                                                type: 'array',
                                                default: [],
                                                items: page
                                            }
                                        }
                                    },
                                }
                            },
                            {
                                type: 'object',
                                required: ['type'],
                                title: 'Oppgaver',
                                properties: {
                                    type: { enum: ['related__assignments'] },
                                    resources: {
                                        type: 'object',
                                        properties: {
                                            relatedList: {
                                                type: 'array',
                                                default: [],
                                                items: assignment
                                            }
                                        }
                                    },
                                }
                            },
                            {
                                type: 'object',
                                required: ['type'],
                                title: 'Innsendte bidrag',
                                properties: {
                                    type: { enum: ['related__contributions'] },
                                    resources: {
                                        type: 'object',
                                        properties: {
                                            relatedList: {
                                                type: 'array',
                                                default: [],
                                                items: contribution
                                            }
                                        }
                                    }
                                }
                            }
                        ]
                    }
                }
            }
        }
    }
};
