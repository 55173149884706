import {
    CURRICULUM_REQUESTED, CURRICULUM_SUCCEEDED, CURRICULUM_FAILED,
    CURRICULUM_UPDATE_REQUESTED, CURRICULUM_UPDATED, CURRICULUM_UPDATE_FAILED
} from '../actions/filter';

const initialState = {
    curriculum_data: null,
    curriculum_fetching: null,
    curriculum_error: false,
    updated: null
};

const curriculum = (state = initialState, action) => {
    switch (action.type) {
        case CURRICULUM_REQUESTED:
            return {
                ...state,
                curriculum_fetching: true,
                curriculum_error: false
            };
        case CURRICULUM_SUCCEEDED:
            return {
                ...state,
                curriculum_data: action.data,
                curriculum_fetching: false,
                curriculum_error: false
            };
        case CURRICULUM_UPDATED:
            let upd = [...state.curriculum_data];

            if (!upd.find(s => s.id === action.subject)) {
                upd = upd.concat({ id: action.subject, children: [{ id: action.level, children: [] }] });
            }

            upd = upd.map(s => {
                if (s.id === action.subject && s.children) {
                    if (!s.children.find(c => c.id === action.level)) {
                        s.children = s.children.concat([{ id: action.level, children: [] }]);
                    }

                    s.children = s.children.map(l => {
                        if (l.id === action.level) {
                            if (action.status === 'deleted') {
                                l.children = l.children.filter(c => {
                                    if (c.id !== action.data.id) {
                                        return c;
                                    }

                                    return false;
                                });
                            } else if (action.status === 'added') {
                                l.children = l.children.concat([action.data]);
                            } else {
                                l.children = l.children.map(c => {
                                    return c.id === action.data.id ? action.data : c;
                                });
                            }
                        }

                        return l;
                    });
                }

                return s;                
            });

            return {
                ...state,
                curriculum_data: upd,
                updated: Date.now()
            };
        case CURRICULUM_FAILED:
            return {
                ...state,
                curriculum_data: [],
                curriculum_fetching: false,
                curriculum_error: true
            };
        default:
            return {
                ...state
            }
    }
};

export default curriculum;
