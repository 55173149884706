import React from 'react';
import { Switch } from 'react-router-dom';
import { AuthsRoutes, PrivateRoute } from '@ekultur/header-state';
import { UserAccess } from './App/UserAccess';

import { ArticlesList } from './Article/ArticlesList';
import { ArticleEditor } from './Article/ArticleEditor';
import { ArticlePreview } from './Article/ArticlePreview';
import ImageGallery from './Article/imageGallery/imageGallery';
import CurriculumEditor from './Curriculum/CurriculumEditor';
import SubjectLevelEditor from './SubjectLevel/SubjectLevelEditor';
import CategoryEditor from './Category/CategoryEditor';

export const AppRoutes = () => {
    return (
        <UserAccess>
            <Switch>
                <PrivateRoute exact path={`${process.env.PUBLIC_URL}/`} component={ArticlesList}/>
                <PrivateRoute exact path={`${process.env.PUBLIC_URL}/gallery`} component={ImageGallery}/>
                <PrivateRoute exact path={`${process.env.PUBLIC_URL}/curriculum`} component={CurriculumEditor}/>
                <PrivateRoute exact path={`${process.env.PUBLIC_URL}/level`} component={SubjectLevelEditor}/>
                <PrivateRoute exact path={`${process.env.PUBLIC_URL}/subject`} component={SubjectLevelEditor}/>
                <PrivateRoute exact path={`${process.env.PUBLIC_URL}/category`} component={CategoryEditor}/>
                <PrivateRoute exact path={`${process.env.PUBLIC_URL}/article/edit/:articleId`} component={ArticleEditor}/>
                <PrivateRoute exact path={`${process.env.PUBLIC_URL}/article/preview/:articleId`} component={ArticlePreview}/>
                <AuthsRoutes/>
            </Switch>
        </UserAccess>
    )
};
