import React from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import useTheme from '@material-ui/core/styles/useTheme';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PermMedia from '@material-ui/icons/PermMedia';
import HomeIcon from '@material-ui/icons/Home';
import InfoIcon from '@material-ui/icons/Info';
import ListIcon from '@material-ui/icons/List';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    drawer: {
        '.home &': {
            [theme.breakpoints.up('md')]: {
                width: drawerWidth,
                flexShrink: 0
            },
            zIndex: theme.zIndex.appBar - 1
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        '.home &': {
            width: drawerWidth
        }
    }
}));

export const Menu = ({container, mobileOpen, closeHandler, visible=true, forceMobile=false}) => {
    const history = useHistory(),
        location = useLocation(),
        classes = useStyles(),
        theme = useTheme();

    const menu = [
        {
            title: 'Hjem',
            url: '/',
            icon: <HomeIcon />,
        },
        {
            title: 'Om nettstedet',
            // url: '/article/edit/860',
            url: '/article/edit/872',
            icon: <InfoIcon />,
        },
        {
            title: 'Mediegalleri',
            url: '/gallery',
            icon: <PermMedia />,
        },
        {
            title: 'Fag',
            url: '/subject',
            icon: <ListIcon />,
        },
        {
            title: 'Trinn',
            url: '/level',
            icon: <ListIcon />,
        },
        {
            title: 'Tema',
            url: '/category',
            icon: <ListIcon />,
        },
        {
            title: 'Kompetansemål',
            url: '/curriculum',
            icon: <ListIcon />,
        }
    ];
    const path = location.pathname;

    const drawer = (
        <div>
            <div className={classes.toolbar} />
            <List>
                {menu.map(item => (
                    <ListItem
                        selected={path === item.url}
                        button
                        key={item.title}
                        onClick={() => {
                            history.push(item.url);
                            closeHandler();
                        }}
                    >
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.title} />
                    </ListItem>
                ))}
            </List>
        </div>
    );

    return (
        <nav className={classes.drawer} aria-label="admin menu">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden mdUp implementation="css">
                <Drawer
                    container={container}
                    variant="temporary"
                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                    open={mobileOpen}
                    onClose={closeHandler}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    {drawer}
                </Drawer>
            </Hidden>
            <Hidden smDown={!forceMobile} xlDown={forceMobile} implementation="css">
                <Drawer
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    variant="permanent"
                    open
                >
                    {drawer}
                </Drawer>
            </Hidden>
        </nav>
    );
};
