import { combineReducers } from 'redux';
import { headerReducer } from '@ekultur/header-state';
import articleList from './redux/reducers/articleList';
import userAccess from './redux/reducers/userAccess';
import article from './redux/reducers/article';
import { resources, video, dmSearch } from './redux/reducers/media';
import layout from './redux/reducers/layout';
import app from './redux/reducers/app';
import mashups from './redux/reducers/mashup';
import level from './redux/reducers/level'
import subject from './redux/reducers/subject';
import category from './redux/reducers/category';
import curriculum from './redux/reducers/curriculum';
import { imageData } from './redux/reducers/imageData';
import selectedSubjectLevel from './redux/reducers/selectedSubjectLevel';
import relatedArticleList from 'portal/src/redux/relatedArticlesReducer';
import curriculumByIds from 'portal/src/redux/curriculumByIdsReducer';

export default combineReducers({
    header: headerReducer,
    app,
    userAccess,
    articleList,
    article,
    resources,
    video,
    dmSearch,
    layout,
    mashups,
    level,
    subject,
    category,
    curriculum,
    imageData,
    selectedSubjectLevel,
    relatedArticleList,
    curriculumByIds
});
