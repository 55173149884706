import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTheme } from '@material-ui/core/styles';
import Search from '@material-ui/icons/Search';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import Pagination from '@material-ui/lab/Pagination';

import { requestArticles } from '../../redux/actions/article';
import { requestFilter } from '../../redux/actions/filter';

import { SearchFilters } from 'common/search/SearchFilters';
import { FilterChipRow } from 'common/search/FilterChipRow';
import { setParams } from 'common/search/searchParams';

import { Accordion } from '../schema/widgets/Accordion';
import { Error } from '../../common/Error';
import { ProgressIndicator } from '../../common/ProgressIndicator';
import useMashupStyles from '../../Mashup/MashupStyles';

import { ArticleListCard } from '../ArticleListCard';
import { relatedObjMapper } from './relatedObjMapper';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
    list: props => ({...props}),
    header: {
        display: 'flex',
        height: spacing(8),
        backgroundColor: '#f2f2f2',
        boxShadow: '0 1.5px 4px rgba(0, 0, 0, 0.12)',
        zIndex: 1,
        '& input': {
            width: '100%',
            height: '100%',
            padding: 0
        },
        '& input, & button': {
            border: 'none',
            outline: 'none',
            backgroundColor: 'transparent'
        },
        '& button': {
            cursor: 'pointer'
        }
    },
    searchIcon: {
        width: spacing(8),
        height: spacing(8),
        padding: spacing(2.5),
        boxSizing: 'border-box'
    },
    autoCompleteWrapper: {
        display: 'flex',
        flex: '1 1 auto'
    },
    resourceSection: {
        marginTop: 10,
        marginBottom: spacing(1),
        height: 'calc(100% - 74px)',
        overflowY: 'scroll'
    },
    paginator: {
        padding: 10
    }
}));


export const RelatedArticles = ({ onRelatedSelected, onRelatedClose, relatedConfig, hidden }) => {
    const dispatch = useDispatch(),
        theme = useTheme(),
        { root } = useMashupStyles(theme),
        classes = useStyles(root),
        { type } = relatedConfig,
        { articles, fetching, error, countFound } = useSelector(state => state.articleList),
        { level_data } = useSelector(state => state.level),
        { subject_data } = useSelector(state => state.subject),
        { category_data } = useSelector(state => state.category),
        [page, setPage] = useState(1),
        itemsPerPage = 10,
        [noOfPages, setNoOfPages] = useState(0),
        [searchExpanded, setSearchExpanded] = useState(true),
        [searchTerm, setSearchTerm] = useState(''),
        [searchParams, setSearchParams] = useState(''),
        [form, setForm] = useState(null),
        typeTrans = {
            article: 'ressurser',
            assignment: 'oppgaver',
            contribution: 'innsendte bidrag'
        };

    useEffect(() => {
        dispatch(requestFilter('level'));
        dispatch(requestFilter('subject'));
        dispatch(requestFilter('category'));
    }, [dispatch]);

    useEffect(() => {
        if ((level_data.length === 0 || subject_data.length === 0 || category_data.length === 0) || !!form) {
            return;
        }

        setForm({
            filters: [],
            radioButtonValue: 'article',
            status: 'published',
            searchTerm: '',
            offset: 0,
            // limit: 20
        });
    }, [level_data, subject_data, category_data, form]);

    useEffect(() => {
        if (type) {
            const update = {
                ...form,
                radioButtonValue: type,
                offset: 0
            };

            setPage(1);
            setForm(update);
            setSearchParams(setParams(update));
        }
    }, [type]);

    useEffect(() => {
        dispatch(requestArticles(searchParams));
    }, [dispatch, searchParams]);

    useEffect(() => {
        setNoOfPages(Math.ceil(countFound / itemsPerPage));
    }, [countFound]);

    if (error) {
        return <Error />;
    }

    if (!form) {
        return <ProgressIndicator/>;
    }

    const closeHandler = e => {
        onRelatedClose && onRelatedClose();
    };

    const handleChangePagination = (e, value) => {
        setPage(value);
        updateForm({ ...form, offset: (value - 1) * itemsPerPage }, false);
    };

    const updateForm = (update, resetPaging = true) => {
        if (resetPaging) {
            setPage(1);
            setSearchParams(setParams({...update, offset: 0}));
            setForm({...update, offset: 0});
        } else {
            setSearchParams(setParams(update));
            setForm(update);
        }
    };

    const handleFilterChange = filters => {
        updateForm({ ...form, filters: form.filters.concat(filters) });
    };

    const handleDeleteFilter = id => {
        updateForm({ ...form, filters: form.filters.filter(f => f.id !== id) });
    };

    const handleKeyDown = e => {
        if (e.key === 'Enter') {
            updateForm({ ...form, searchTerm: searchTerm || ''});
        }
    };

    const renderArticleCard = (item, index) => {
        if (item.page_type === 'owner') {
            return null;
        }

        const obj = relatedObjMapper(item)
        const onClick = () => {
            onRelatedSelected && onRelatedSelected(obj);
        };
        return (
            <ArticleListCard article={item} key={`${index}`} onSelect={onClick}/>
        )
    };

    if (hidden) {
        return null;
    }

    return (
        <>
            <div className={classes.header}>
                <Search className={classes.searchIcon} />
                <input
                    type="text"
                    placeholder="Finn artikler"
                    onChange={e => setSearchTerm(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
                <button onClick={closeHandler}><strong>«&nbsp;</strong>Lukk</button>
            </div>
            <section className={classes.resourceSection}>
                <Accordion
                    title={`${(countFound) || 0} treff i publiserte ${typeTrans[type]}`}
                    component="div"
                    expanded={searchExpanded}
                    onToggle={() => setSearchExpanded(!searchExpanded)}
                >
                    <div className={classes.autoCompleteWrapper}>
                        <SearchFilters
                            onChange={handleFilterChange}
                            selectedFilters={form.filters}
                            variant="filled"
                        />
                    </div>
                    <div className={classes.chipWrapper}>
                        <FilterChipRow filters={form.filters} handleDeleteFilter={handleDeleteFilter} />
                    </div>
                    <List className={classes.list}>
                        {articles.map(renderArticleCard)}
                    </List>
                    <Divider />
                    {(countFound > itemsPerPage) && (
                        <Box component="span">
                            <Pagination
                                count={noOfPages}
                                page={page}
                                onChange={handleChangePagination}
                                defaultPage={1}
                                color="primary"
                                size="large"
                                showFirstButton
                                showLastButton
                                classes={{ ul: classes.paginator }}
                            />
                        </Box>
                    )}
                </Accordion>
            </section>
        </>
    );
};
