import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, List } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import TextField from '@material-ui/core/TextField';
import { useTheme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
// import CircularProgress from '@material-ui/core/CircularProgress';

import { requestArticles } from '../redux/actions/article';
import { requestFilter } from '../redux/actions/filter';

import { SearchFilters } from 'common/search/SearchFilters';
import { FilterChipRow } from 'common/search/FilterChipRow';
import { setParams } from 'common/search/searchParams';

import { Error } from '../common/Error';
import {ProgressIndicator} from '../common/ProgressIndicator';
import useMashupStyles from '../Mashup/MashupStyles';
import { ArticleListCard } from './ArticleListCard';

import { RadioButtonFilter } from 'portal/src/content/RadioButtonFilter';

const useStyles = makeStyles(({spacing}) => ({
    list: props => ({...props}),
    addBtn: {
        marginTop: '1em',
        marginLeft: '1em',
        marginBottom: '1em',
    },
    searchMargin: {marginLeft: '1em'},
    paginator: {
        justifyContent: "center",
        padding: "10px"
    },
    searchFilters: {
        display: 'flex',
        flexFlow: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        marginLeft: '1em',
        marginRight: '1em'
    },
    searchTextfield: {
        flex: '1 1 auto',
        marginRight: 16
    },
    autoCompleteWrapper: {
        display: 'flex',
        flex: '1 1 auto'
    },
    chipWrapper: {
        width: '100%',
        margin: '8px 0 16px'
    },
    wrapperRadioButtonFilter: {
        paddingLeft: 16,
        paddingRight: 8
    }
}));

let timer;

export const ArticlesList = ({ match }) => {
    const dispatch = useDispatch(),
        history = useHistory(),
        theme = useTheme(),
        { t } = useTranslation(['dm.article'], { useSuspense: false }),
        { root } = useMashupStyles(theme),
        classes = useStyles(root),
        { articles, fetching, error, countFound } = useSelector(state => state.articleList),
        { level_data } = useSelector(state => state.level),
        { subject_data } = useSelector(state => state.subject),
        { category_data } = useSelector(state => state.category),
        [page, setPage] = useState(1),
        itemsPerPage = 10,
        [noOfPages, setNoOfPages] = useState(0),
        [searchTerm, setSearchTerm] = useState(''),
        [searchParams, setSearchParams] = useState(''),
        [form, setForm] = useState(null),
        orderTags = [
            {
                value: 'promoted',
                label: 'Aktuelt først'
            },
            {
                value: 'updated',
                label: 'Sist oppdatert'
            }
        ];

    useEffect(() => {
        dispatch(requestFilter('level'));
        dispatch(requestFilter('subject'));
        dispatch(requestFilter('category'));
    }, [dispatch]);

    useEffect(() => {
        if ((level_data.length === 0 || subject_data.length === 0 || category_data.length === 0) || !!form) {
            return;
        }

        setForm({
            filters: [],
            radioButtonValue: null,
            orderBy: 'updated',
            searchTerm: '',
            offset: 0,
            // limit: 20
        });
    }, [level_data, subject_data, category_data, form]);

    useEffect(() => {
        dispatch(requestArticles(searchParams));
    }, [dispatch, searchParams]);

    useEffect(() => {
        setNoOfPages(Math.ceil(countFound / itemsPerPage));
    }, [countFound]);

    if (error) {
        return <Error />;
    }

    if (!form) {
        return <ProgressIndicator/>;
    }

    const handleChangePagination = (e, value) => {
        setPage(value);
        updateForm({ ...form, offset: (value - 1) * itemsPerPage }, false);
    };

    const onSelect = pageId => {
        history.push(`/article/edit/${pageId}`);
    };

    const updateForm = (update, resetPaging = true) => {
        if (resetPaging) {
            setPage(1);
            setSearchParams(setParams({...update, offset: 0}));
            setForm({...update, offset: 0});
        } else {
            setSearchParams(setParams(update));
            setForm(update);
        }
    };

    const handleFilterChange = filters => {
        updateForm({ ...form, filters: form.filters.concat(filters) });
    };

    const handleDeleteFilter = id => {
        updateForm({ ...form, filters: form.filters.filter(f => f.id !== id) });
    };

    const handleKeyDown = e => {
        if (e.key === 'Enter' && searchTerm) {
            updateForm({ ...form, searchTerm: searchTerm });
        }
    };

    const handleClearSearch = (e) => {
        setSearchTerm('');
        updateForm({ ...form, searchTerm: '' });
    };

    const handleFormChange = update => {
        updateForm(update);
        // history.push({ search: setParams(update) });
    };

    const renderArticleCard = (item, index) => {
        if (item.page_type === 'owner') {
            return null;
        }

        return (
            <ArticleListCard article={item} onSelect={onSelect} key={`${index}`} />
        )
    };

    return (
        <>
            <Button className={classes.addBtn} variant="contained" color="primary" onClick={() => onSelect('new')}>
                {t(`article-list.add.${match.path}`, 'Opprett ny artikkel')}
            </Button>
            <Button className={classes.addBtn} variant="contained" color="primary" onClick={() => onSelect('newAssignment')}>
                Opprett ny oppgave
            </Button>
            <div className={classes.searchFilters}>
                <div className={classes.searchTextfield}>
                    <TextField fullWidth id="standard-basic" placeholder="Søk i artikler" variant="filled"
                        onChange={e => setSearchTerm(e.target.value)}
                        onKeyDown={handleKeyDown}
                        value={searchTerm}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon color="primary" />
                                </InputAdornment>
                            ),
                            endAdornment: searchTerm && (
                                <IconButton
                                    style={{ color: 'white' }}
                                    onClick={handleClearSearch}
                                >
                                    <CancelIcon />
                                </IconButton>
                            )
                        }}
                    />
                </div>
                <div className={classes.autoCompleteWrapper}>
                    <SearchFilters
                        onChange={handleFilterChange}
                        selectedFilters={form.filters}
                        variant="filled"
                    />
                </div>
                <div className={classes.chipWrapper}>
                    <FilterChipRow filters={form.filters} handleDeleteFilter={handleDeleteFilter} />
                </div>
            </div>
            <Divider />
            <div className={classes.wrapperRadioButtonFilter}>
                <RadioButtonFilter onChangeForm={handleFormChange} form={form} fetching={fetching} total={countFound} tags={orderTags} />
            </div>
            <Divider />
            <List className={`${classes.list} ${classes.searchMargin}`}>
                {articles && articles.map(renderArticleCard)}
                {/* {articles
                    ? articles.slice((page - 1) * itemsPerPage, page * itemsPerPage).map(renderArticleCard)
                    : null
                } */}
            </List>
            <Divider />
            {(countFound > itemsPerPage) && (
                <Box component="span">
                    <Pagination
                        count={noOfPages}
                        page={page}
                        onChange={handleChangePagination}
                        defaultPage={1}
                        color="primary"
                        size="large"
                        showFirstButton
                        showLastButton
                        classes={{ ul: classes.paginator }}
                    />
                </Box>
            )}
        </>
    );
};

export default ArticlesList;
