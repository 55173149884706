export default {
    type: 'object',
    properties: {
        id: {
            type: 'integer',
            readOnly: true
        },
        description: {
            title: 'Kompetansemål',
            type: 'string'
        }
    }
}
