import { SUBJECT_LEVEL_CHANGE } from '../actions/selectedSubjectLevel';

const initialState = {
    subjects: [],
    levels: []
};

const selectedSubjectLevel = (state = initialState, action) => {
    if (action.type === SUBJECT_LEVEL_CHANGE) {
        return {
            ...state,
            [action.data.type]: action.data.value
        };
    }

    return {
        ...state
    }
};

export default selectedSubjectLevel;
