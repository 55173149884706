import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { kitFetch } from '@ekultur/header-state';

import config from 'common/config';
import { Main } from 'portal/src/content/Main';
import { ProgressIndicator } from '../common/ProgressIndicator';

const useStyles = makeStyles(({ spacing }) => ({
    preview: {
        position: 'absolute',
        width: '100%',
        height: 'calc(100% - 64px)',
        overflowY: 'scroll',
        '& .article__content': {
            margin: '0 auto 0',
            minHeight: spacing(4),
            lineHeight: '1.5em',
            maxWidth: '600px'
        }
    }
}));


export const ArticlePreview = ({ match }) => {
    const [page, setPage] = useState(),
        { params } = match,
        pageId = params && params.articleId,
        classes = useStyles();

    useEffect(() => {
        kitFetch(`${config.get('articleApi')}minstemme/page/${pageId}/`)
            .then(res => setPage(res))
            .catch(error => setPage());
    }, [pageId]);

    return (
        <div className={classes.preview}>
            {page
                ? <Main data={page} anchorObj={false} />
                : <ProgressIndicator />
            }
        </div>
    );
};
