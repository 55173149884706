import React, { useEffect, useRef, useState } from 'react';
import Form from '@rjsf/material-ui';
import { useDispatch, useSelector } from 'react-redux';
import { createMuiTheme, makeStyles, useTheme } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import SearchIcon from '@material-ui/icons/Search';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Pagination from '@material-ui/lab/Pagination';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Link from '@material-ui/core/Link';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import { dmsUrl } from 'common/dms/dmsUrl';
import {
    requestResources, updateResources, uploadMedia, deleteResource,
    uploadVideo, uploadVideoStatus, publishVideo, videoDialogDismissed
} from '../../redux/actions/media';
import FileSelector from './fileSelector';
import imageFileSchema from './schema/imageFileSchema';
import imageFileUiSchema from './schema/imageFileUiSchema';
import { ProgressIndicator } from '../../common/ProgressIndicator';
import { ConfirmDialog } from '../ArticleListActions/ConfirmDialog';
import { UploadDialog } from '../mediaLib/UploadDialog';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
        marginTop: 20,
    },
    gridList: {
        width: '100%'
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)'
    },
    appBar: {
        position: 'relative',
        backgroundColor: '#006C85'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1
    },
    image: {
        cursor: 'pointer'
    },
    wrapper: {
        padding: 10,
        maxWidth: 1920,
        marginLeft: 'auto',
        marginRight: 'auto',
        '& .upload-buttons': {
            display: 'flex',
            '& > button': {
                marginRight: '1em'
            }
        }
    },
    wrapperSearchAndFormGroup: {
        // marginTop: 80,
        display: 'flex'
    },
    searchTextfield: {
        width: 400,
        marginBottom: 15,
        marginRight: 40
    },
    button: {
        marginTop: 10
    },
    searchInput: {
        borderRadius: 8,
        width: '100%',
        height: 48,
        [theme.breakpoints.down('sm')]: {
            width: 250,
        },
    },
    input: {
        display: 'none'
    },
    paginator: {
        padding: 10
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

let timer = null;


const ImageGallery = () => {
    const classes = useStyles(),
        theme = useTheme(),
        dispatch = useDispatch(),
        { user, owner } = useSelector(state => state.header),
        { data, fetching, countFound } = useSelector(state => state.resources),
        { uploadedId, status } = useSelector(state => state.video),
        [selectedTile, setSelectedTile] = useState(null),
        [confirmOpen, setConfirmOpen] = useState(false),
        [isEditDialogOpen, setIsEditDialogOpen] = useState(false),
        matches = useMediaQuery(theme.breakpoints.down('sm')),
        columns = matches ? 2 : 4,
        pageRef = useRef(),
        [q, setQ] = useState(''),
        [searchTerm, setSearchTerm] = useState(''),
        [mediaType, setMediaType] = useState('media'),
        [page, setPage] = useState(1),
        [noOfPages, setNoOfPages] = useState(0),
        itemsPerPage = 24;

    const theme2 = createMuiTheme({
        palette: {
            primary: {
                main: '#006C85',
            },
            secondary: {
                main: '#E9EFF2',
            },
            action: {
                main: '#0083A8'
            }
        },
        typography: {
            fontFamily: [
                'Roboto',
                'sans-serif',
            ].join(','),
        },
    });

    useEffect(() => {
        dispatch(requestResources(owner.uniqueId, mediaType, searchTerm, itemsPerPage, (page - 1) * itemsPerPage));
    }, [dispatch, owner.uniqueId, searchTerm, mediaType, itemsPerPage, page]);

    useEffect(() => {
        setPage(1);
    }, [mediaType]);

    useEffect(() => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            setSearchTerm(q);
            setPage(1);
        }, 500);
    }, [q]);

    useEffect(() => {
        setNoOfPages(Math.ceil(countFound / itemsPerPage));
    }, [countFound]);

    useEffect(() => {
        if (uploadedId && status === 'init') {
            dispatch(uploadVideoStatus(user.uniqueId, uploadedId));
        } else if (uploadedId && status === 'done') {
            dispatch(publishVideo(user.uniqueId, uploadedId));
        }
    }, [dispatch, uploadedId, status, user.uniqueId]);

    const onFormChanged = form => {
        pageRef.current = form.formData;
    };

    const onSave = () => {
        dispatch(updateResources(user, pageRef.current, selectedTile));
        setIsEditDialogOpen(false);
    };

    const uploadHandler = file => {
        dispatch(uploadMedia(file, user.uniqueId));
    };

    const uploadVideoHandler = e => {
        dispatch(uploadVideo(e.target.files, user.uniqueId));
    };

    const uploadDialogHandler = e => {
        dispatch(videoDialogDismissed());
    };

    const editDialogOpen = tile => {
        setIsEditDialogOpen(true);
        setSelectedTile(tile);
    };

    const editDialogClose = () => {
        setIsEditDialogOpen(false);
    };

    const onDeleteOpen = tile => {
        setConfirmOpen(true);
        setSelectedTile(tile);
    }

    const onDeleteConfirm = () => {
        dispatch(deleteResource(selectedTile.id));
        setConfirmOpen(false);
        setSelectedTile();
    }

    const handleChangeRadio = (event) => {
        setMediaType(event.target.value);
    };

    const handleChangePagination = (e, value) => {
        setPage(value);
    };

    return (
        <ThemeProvider theme={theme2}>
            <div className={classes.wrapper}>
                <div className={classes.wrapperSearchAndFormGroup}>
                    <div className={classes.searchTextfield}>
                        <TextField fullWidth id="standard-basic" placeholder="Søk etter media med fritekst og stikkord"
                            variant="outlined"
                            value={q}
                            onChange={e => setQ(e.target.value)}
                            InputProps={{
                                className: classes.searchInput,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon color="primary" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <div>
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Radio
                                        checked={mediaType === 'media'}
                                        onChange={handleChangeRadio}
                                        name="all"
                                        color="primary"
                                        value="all"
                                    />
                                }
                                label="Alle"
                            />
                            <FormControlLabel
                                control={
                                    <Radio
                                        checked={mediaType === 'image'}
                                        onChange={handleChangeRadio}
                                        name="image"
                                        color="primary"
                                        value="image"
                                    />
                                }
                                label="Bilder"
                            />
                            <FormControlLabel
                                control={
                                    <Radio
                                        checked={mediaType === 'audio'}
                                        onChange={handleChangeRadio}
                                        name="audio"
                                        color="primary"
                                        value="audio"
                                    />
                                }
                                label="Audio"
                            />
                            <FormControlLabel
                                control={
                                    <Radio
                                        checked={mediaType === 'video'}
                                        onChange={handleChangeRadio}
                                        name="video"
                                        color="primary"
                                        value="video"
                                    />
                                }
                                label="Video"
                            />
                        </FormGroup>
                    </div>
                </div>
                <div className="upload-buttons">
                    <FileSelector uploadHandler={uploadHandler} />
                    <Input
                        accept="video/*"
                        className={classes.input}
                        id="button-video-upload"
                        type="file"
                        onChange={uploadVideoHandler}
                    />
                    <label htmlFor="button-video-upload">
                        <Button variant="contained" startIcon={<CloudUploadIcon />} color="default" component="span">
                            Last opp video
                        </Button>
                    </label>
                </div>
                {(fetching || (!data || !data.resources))
                    ? <ProgressIndicator />
                    : (
                        <div className={classes.root}>
                            <GridList cellHeight={300} cols={columns} className={classes.gridList}>
                                {(data && data.resources) && data.resources.map(tile => (
                                    <GridListTile key={tile && tile.id} style={{ minWidth: 400 }}>
                                        <img src={tile && dmsUrl(tile)} alt={tile && tile.mimetype && tile.mimetype}
                                            className={classes.image}
                                            onClick={() => editDialogOpen(tile)} />
                                        <GridListTileBar
                                            title={tile && tile.filename}
                                            subtitle={
                                                <span>eier: {tile && tile.owner_identifier && tile.owner_identifier}</span>}
                                            actionIcon={
                                                <IconButton
                                                    aria-label={`info about ${tile && tile.filename}`}
                                                    className={classes.icon}
                                                    value={tile && tile.id}
                                                    onClick={() => onDeleteOpen(tile)}
                                                >
                                                    <DeleteForeverIcon />
                                                </IconButton>
                                            }
                                        />
                                    </GridListTile>
                                ))}
                            </GridList>
                            <Dialog
                                open={isEditDialogOpen}
                                onClose={editDialogClose}
                                TransitionComponent={Transition}
                            >
                                <AppBar className={classes.appBar}>
                                    <Toolbar>
                                        <Typography variant="h6" className={classes.title}>
                                            {selectedTile && selectedTile.filename}
                                        </Typography>
                                        <IconButton
                                            edge="start"
                                            color="inherit"
                                            onClick={editDialogClose}
                                            aria-label="close"
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Toolbar>
                                </AppBar>
                                <Box m={3}>
                                    <Form
                                        schema={imageFileSchema}
                                        uiSchema={imageFileUiSchema}
                                        formData={selectedTile && selectedTile.data}
                                        onChange={onFormChanged}
                                    >
                                        <Button type="submit" variant="contained" color="primary" className={classes.button}
                                            onClick={onSave}>Lagre</Button>
                                    </Form>
                                </Box>
                            </Dialog>
                            <Divider />
                            {(countFound > itemsPerPage) && (
                                <Box component="span">
                                    <Pagination
                                        count={noOfPages}
                                        page={page}
                                        onChange={handleChangePagination}
                                        defaultPage={1}
                                        color="primary"
                                        size="large"
                                        showFirstButton
                                        showLastButton
                                        classes={{ ul: classes.paginator }}
                                    />
                                </Box>
                            )}
                        </div>
                    )}
                {selectedTile && selectedTile.used_in_pages && selectedTile.used_in_pages.length
                    ? (
                        <ConfirmDialog
                            title="Det finnes lenker til dette innholdet!"
                            cancelLabel="Ok"
                            open={confirmOpen}
                            setOpen={setConfirmOpen}
                        >
                            <Typography>Vennligst fjern referanse i disse artiklene før du sletter:</Typography>
                            <List disablePadding={true}>
                                {selectedTile.used_in_pages.map(ref => (
                                    <ListItem disableGutters={true} dense={true}>
                                        <Link key={ref.page_id} href={`/article/edit/${ref.page_id}`} target="_blank" rel="noreferrer">
                                            {ref.title}
                                        </Link>
                                    </ListItem>
                                ))}
                            </List>
                        </ConfirmDialog>
                    ) : (
                        <ConfirmDialog
                            title="Slett medie"
                            confirmLabel="Ja"
                            cancelLabel="Nei"
                            open={confirmOpen}
                            setOpen={setConfirmOpen}
                            onConfirm={onDeleteConfirm}
                        >
                            Er du sikker?
                        </ConfirmDialog>
                    )}
                {['init', 'convert', 'converting', 'done', 'publishing', 'published', 'failed'].includes(status) && <UploadDialog status={status} closeHandler={uploadDialogHandler} />}
            </div>
        </ThemeProvider>
    );
};

export default ImageGallery;
