import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { Header } from '@ekultur/header-state';

import { Theme } from './Theme';
import { Menu } from './Menu';

const useStyles = makeStyles(({ spacing }) => ({
    root: {
        display: 'flex',
        width: '100%',
        paddingTop: spacing(8)
    },
    main: {
        flexGrow: 1,
        '&.default, &.gallery': {
            overflowY: 'scroll',
            overflowX: 'hidden',
            position: 'absolute',
            height: 'calc(100% - 64px)'
        },
        '&.default': {
            width: 'calc(100% - 240px)',
            left: 240
        }
    }
}));

const useStyleOverrides = makeStyles(theme => ({
    hamburger: {
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
        '.mobile &': {
            [theme.breakpoints.down('xl')]: {
                display: 'block'
            }
        }
    }
}));


export const Layout = ({ children }) => {
    const location = useLocation(),
        classes = useStyles(),
        styleOverrides = useStyleOverrides(),
        [mobileOpen, setMobileOpen] = React.useState(false),
        { t, ready } = useTranslation(['navigation'], { useSuspense: false }),
        [view, setView] = useState('default');

    useEffect(() => {
        if (location.pathname.startsWith('/article/edit')) {
            setView('editor');
        }
        else if (location.pathname.startsWith('/article/preview')) {
                setView('preview');
        } else if (location.pathname.startsWith('/gallery')) {
            setView('gallery');
        } else {
            setView('default')
        }
    }, [location.pathname]);
    
    return (
        <Theme>
            <div className={`${['/', '/curriculum', '/subject', '/level', '/category'].includes(location.pathname) ? 'home' : 'mobile'}`}>
                <Header
                    mobileOpen={mobileOpen}
                    mobileOpenHandler={setMobileOpen}
                    translateHandler={({ key, defaultValue, options }) => t(key, defaultValue, options)}
                    className={styleOverrides}
                />
                <div className={classes.root}>
                    <Menu
                        mobileOpen={mobileOpen}
                        closeHandler={() => setMobileOpen(false)}
                        forceMobile={!['/', '/curriculum', '/subject', '/level', '/category'].includes(location.pathname)}
                    />
                    <main className={`${classes.main} ${view}`}>
                        {children}
                    </main>
                </div>
            </div>
        </Theme>
    );
};
