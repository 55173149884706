export const relatedObjMapper = item => {
    return {
        cardImage: item.cardImage,
        headerImage: item.headerImage,
        id: item.id,
        dimu_unique_id: item.dimu_unique_id,
        legacyId: item.legacyId,
        title: item.title,
        type: item.type
    }
};
