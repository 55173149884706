import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

export const SaveActions = ({ onSave, status, disabled = true }) => {
    const [open, setOpen] = React.useState(false),
        { t } = useTranslation(['dm.article'], { useSuspense: false }),
        anchorRef = React.useRef(null),
        options = [
            { label: t('save', 'Lagre'), status: `${status === 'published' ? 'published' : 'draft'}` },
            { label: `${status === 'published' ? t('save_unpublish', 'Lagre og avpubliser') : t('save_publish', 'Lagre og publiser')}`, status: `${status === 'published' ? 'draft' : 'published'}` }
        ];

    const handleMenuItemClick = index => {
        onSave && onSave(options[index].status);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
        <>
            <ButtonGroup variant="contained" color="primary" ref={anchorRef} disabled={disabled} aria-label="split button">
                <Button onClick={() => handleMenuItemClick(0)}>{options[0].label}</Button>
                <Button
                    color="primary"
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                >
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu">
                                    {options.map((option, index) => (
                                        <MenuItem
                                            key={option.label}
                                            disabled={index === 2}
                                            onClick={() => handleMenuItemClick(index)}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};
