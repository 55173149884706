import React, { useState, useEffect, memo, useRef } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Button, Chip, Typography, Link } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Form from '@rjsf/material-ui';

import config from 'common/config';

import { ProgressIndicator } from '../common/ProgressIndicator';
// import { Unauthorized } from '../common/Unauthorized';
import { Error } from '../common/Error';
import { Main as Preview } from 'portal/src/content/Main';
import { MediaLib } from './mediaLib/MediaLib';
import { RelatedArticles } from './related/relatedArticles';
import { SaveActions } from './SaveActions';

import { baseSchema, articleSchema, ownerSchema, uiSchema } from './schema';

import { requestArticle, requestNewArticle, updateArticle } from '../redux/actions/article';

const NoWrapperFieldTemplate = props => {
    return props.children;
};


const useStyles = makeStyles(({ spacing, breakpoints }) => ({
    progress: {
        position: 'relative',
        top: spacing(8)
    },
    root: {
        position: 'absolute',
        top: 0,
        height: '100vh',
        width: '150%',
        marginLeft: '-50%',
        display: 'flex',
        boxSizing: 'border-box',
        paddingTop: spacing(15),
        transition: 'margin-left .3s',
        '&.mediaLib': {
            marginLeft: 0
        }
    },
    actions: {
        display: 'flex',
        position: 'absolute',
        width: '99vw',
        height: spacing(7),
        top: spacing(8),
        left: '50vw',
        alignItems: 'center',
        zIndex: 1,
        color: 'rgba(0, 0, 0, .87)',
        '& > *': {
            marginRight: spacing(2),
            flex: '0 0 auto',
            flexDirection: 'row'
        },
        '& > a': {
            margin: '0 auto 0 1em',
            '&:hover': {
                cursor: 'pointer'
            }
        }
    },
    saved: {
        width: 122,
        display: 'flex',
        justifyContent: 'space-around',
        color: 'green'
    },
    finder: {
        width: '33.33%',
        // overflowY: 'scroll',
        backgroundColor: '#e2e2e2',
        borderTop: '1px solid #ddd',
        // '& > section:first-of-type': {
        //     marginTop: spacing(9)
        // }
    },
    form: {
        width: '33.33%',
        marginBottom: '.25em',
        overflowY: 'scroll',
        overflowX: 'hidden',
        border: '1px solid #ddd',
        '& .MuiButton-root': {
            minWidth: 'auto',
        },
        '& > .MuiFormControl-root > .MuiGrid-root': {
            marginTop: 0,
            '& > .MuiGrid-item': {
                paddingTop: 0
            }
        },
        '& .MuiInputBase-inputMultiline': {
            resize: 'vertical'
        }
    },
    preview: {
        width: '33.33%',
        overflowY: 'scroll',
        overflowX: 'hidden',
        backgroundColor: '#fff',
        fontSize: '18px',
        '& h2': {
            margin: '1.5em 0 1em',
            fontSize: '1.25em',
            lineHeight: '1.25',
            fontWeight: 'bold'
        },
        '&.article': {
            paddingTop: '.3em',
            borderTop: '1px solid #ddd',
            borderBottom: '1px solid #ddd',
            '& .body': {
                paddingTop: '.1em',
                paddingBottom: '2em'
            }
        },
        '& .body': {
            position: 'relative',
            minWidth: '200%',
            height: '100%',
            top: '-25%',
            left: '-50%',
            transform: 'scale(0.5, 0.5)'
        },
        '&.anchorMode': {
            cursor: 'alias'
        }
    }
}));


export const ArticleEditor = memo(({match}) => {
    const dispatch = useDispatch(),
        history = useHistory(),
        {params} = match,
        {user} = useSelector(state => state.header),
        {data, created, fetching, error, saved} = useSelector(state => state.article),
        // { features } = useSelector(state => state.features),
        pageRef = useRef(),
        [ready, setReady] = useState(false),
        [mediaLibIdPath, setmediaLibIdPath] = useState(),
        [relatedListIdPath, setRelatedListIdPath] = useState(),
        [mediaLibConfig, setMediaLibConfig] = useState({dmOnly: false, items: [], open: null}),
        [relatedConfig, setRelatedConfig] = useState({items: [], open: null}),
        [pageChange, setPageChange] = useState(0),
        [focusedId, setFocusedId] = useState(),
        [anchorMode, setAnchorMode] = useState(false),
        {t} = useTranslation(['dm.article'], {useSuspense: false}),
        classes = useStyles();

    let schema = baseSchema;

    // const validate = (formData, errors) => errors;

    const onFormChanged = form => {
        console.log('form changed');

        pageRef.current = form.formData;
        setPageChange(pageChange + 1);
    };

    const onFocus = (idPath /*, value*/) => {
        setFocus(idPath);
    };

    const setFocus = idPath => {
        if (!mediaLibConfig.open) {
            setFocusedId(idPath);
        }
    }

    const onSave = newStatus => {
        const p = {...pageRef.current, status: newStatus};

        setPageChange(0);
        dispatch(updateArticle(user.uniqueId, p));
    };

    const onCancel = () => {
        history.push('/');
    };

    const idPathToPropPath = idPath => {
        return idPath.replace('root_', '').replace(/_/g, '.');
    }

    const setPropInPage = (path, value, action = 'add', last = true) => {
        const pathArr = path.split('.'),
            [key] = pathArr.slice(-1);

        let p = {...pageRef.current};

        const updated = pathArr.reduce(function (a, b) {
            if (a && a.hasOwnProperty(b) && b === key) {
                if (Array.isArray(a[b])) {
                    if (['remove', 'update'].includes(action)) {
                        const idx = a[b].findIndex(x => x.image_id === value[0].image_id);
                        if (idx >= 0) {
                            if (action === 'remove') {
                                a[b].splice(idx, 1);
                            } else {
                                a[b][idx] = value[0];
                            }
                        }
                    } else {
                        a[b] = a[b].concat(value);
                    }
                } else {
                    a[b] = value;
                }
            } else if (action === 'add' && b === key) {
                a[b] = value;
            }
            return a && a[b];
        }, p);

        if (last && typeof updated !== 'undefined') {
            pageRef.current = p;
            setPageChange(pageChange + 1);
            console.log('form changed from outside');
        }

        return updated;
    };

    const onMediaLibOpen = (idPath, items, changeHandler, dmOnly = false) => {
        setMediaLibConfig({dmOnly: dmOnly, items: items, changeHandler: changeHandler, open: Date.now()});
        setmediaLibIdPath(idPath);
    };

    const onRelatedOpen = (type, idPath, items, changeHandler) => {
        setRelatedConfig({type: type, items: items, changeHandler: changeHandler, open: Date.now()});
        setRelatedListIdPath(idPath);
    };

    const onMediaLibRemoveIndex = idx => {
        let list = [...mediaLibConfig.items];
        list.splice(idx, 1);
        setMediaLibConfig({ ...mediaLibConfig, items: list });
    };

    const onMediaLibReorder = (from, to) => {
        let list = [...mediaLibConfig.items];
        list.splice(to, 0, list.splice(from, 1)[0]);
        setMediaLibConfig({...mediaLibConfig, items: list});
    };

    const onMediaLibClose = () => {
        setMediaLibConfig({...mediaLibConfig, open: false});
    };

    const onRelatedClose = () => {
        setRelatedConfig({...relatedConfig, open: false});
    };

    const onMediaSelected = (mediaList, remove = false, update = false) => {
        const path = mediaLibIdPath && idPathToPropPath(mediaLibIdPath);

        if (remove) {
            const idx = mediaLibConfig.items.findIndex(x => x.image_id === remove.image_id);
            mediaLibConfig.changeHandler(idx);
            onMediaLibRemoveIndex(idx);
        } else if (path) {
            setMediaLibConfig({ ...mediaLibConfig, items: mediaLibConfig.items.concat(mediaList) });
            setPropInPage(path, mediaList, update ? 'update' : 'add');
        } else {
            alert('Du må velge hvor i artikkelen du vil ha bildet ved å klikke "Legg til bilde"');
        }
    };

    const onRelatedSelected = (relatedList, remove = false, update = false) => {
        const path = relatedListIdPath && idPathToPropPath(relatedListIdPath);

        if (remove) {
            const idx = relatedConfig.items.findIndex(x => x.dms_id === remove.dms_id);
            relatedConfig.changeHandler(idx);
            onMediaLibRemoveIndex(idx);
        } else if (path) {
            setRelatedConfig({ ...relatedConfig, items: relatedConfig.items.concat(relatedList) });
            setPropInPage(path, relatedList, update ? 'update' : 'add');
        } else {
            alert('Du må klikke "Finn relaterte ressurser"');
        }
    };

    const onPreviewClick = (section, block) => {
        const path = 'content.sections.' + section,
            sectionCollapsed = pageRef.current.content.sections[section]._editor._collapse,
            blockCollapsed = pageRef.current.content.sections[section].blocks[block]._editor._collapse;

        let scrollEl = document.getElementById('root_' + path.replace(/\./g, '_') + '_blocks_' + block + '__editor__collapse');

        if (sectionCollapsed) {
            try {
                const s = document.getElementById(`sections_${section}`);
                s.firstElementChild.click();
            } catch (err) {
            }
        }

        if (blockCollapsed) {
            try {
                setTimeout(() => {
                    const s = document.getElementById(`sections_${section}_blocks_${block}`);
                    s.firstElementChild.click();
                }, sectionCollapsed ? 300 : 0);
            } catch (err) {
            }
        }

        while (scrollEl.tagName !== 'LI' && scrollEl.parentElement) {
            scrollEl = scrollEl.parentElement;
        }

        setTimeout(() => {
            scrollEl && scrollEl.scrollIntoView({behavior: 'smooth'});
        }, sectionCollapsed && blockCollapsed ? 800 : blockCollapsed ? 500 : 0);
    };

    const onAddPlace = (place, idPath) => {
        if (idPath) {
            setPropInPage(idPath, place);
        }
    };

    const formatDate = () => {
        const parseISOString = s => {
            const b = s.split(/\D+/);
            return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
        };

        const formatDateString = ds => {
            return `${ds.substr(0, 4)}-${ds.substr(4, 2)}-${ds.substr(6, 2)}T${ds.substr(9, 2)}:${ds.substr(11, 2)}:${ds.substr(13, 2)}.${ds.substr(16, 3)}`;
        };

        return parseISOString(formatDateString(pageRef.current.updated_at)).toLocaleString();

    };

    const openPreview = () => {
        const url = pageRef.current.status === 'published'
            ? `${config.get('portalUrl')}/${pageRef.current.dimu_unique_id}`
            : `/article/preview/${pageRef.current.id}`;
        window.open(url);
    };

    const onAnchorMode = (idPath, value, onChange) => {
        if (!idPath) {
            setAnchorMode(false);
            return;
        }

        const handler = id => {
            setAnchorMode({value: id, handler: handler});
            onChange(id);
        };

        setAnchorMode({value: value, handler: handler});
    };

    useEffect(() => {
        console.log('initializing Article Editor');
    }, []);

    useEffect(() => {
        const artId = params && params.articleId;

        if (!error && !fetching && !created && ['new', 'newAssignment'].includes(artId)) {
            dispatch(requestNewArticle(artId === 'new' ? 'article' : 'assignment'));
        } else if (fetching === null || (!error && !fetching) || (!fetching && (data && artId !== data.id))) {
            if (!data || (!['new', 'newAssignment'].includes(artId) && parseInt(artId) !== data.id)) {
                dispatch(requestArticle(artId));
            } else if (!fetching && !error && data) {
                pageRef.current = data;
                setReady(true);

                if (data.page_type === 'owner') {
                    schema.properties.content.properties = ownerSchema;
                } else {
                    schema.properties.content.properties = articleSchema;
                }

                if (created) {
                    window.history.replaceState(null, '', `/article/edit/${data.id}`);
                }
            }
        }
    }, [dispatch, fetching, error, params, created, data, user.uniqueId, schema.properties.content]);

    if (error) {
        return (
            <div className={classes.progress}>
                <Error/>
            </div>
        );
    }

    /* if (!features || !features.includes('ArticleEditor')) {
        return (
            <div className={classes.progress}>
                <Unauthorized />
            </div>
        );
    } */

    if (!ready) {
        return (
            <div className={classes.progress}>
                <ProgressIndicator/>
            </div>
        );
    }

    return (
        <article className={`${classes.root}${mediaLibConfig.open || relatedConfig.open ? ' mediaLib' : ''}`}>
            <Prompt when={!!pageChange} message={t('unsaved-alert', 'Du har ulagrede endringer som vil gå tapt. Er du sikker på at du vil avslutte?')}/>
            <section className={classes.actions}>
                <Link onClick={onCancel}>Tilbake</Link>
                <Chip label={t(`${pageRef.current.status}`)} onDelete={() => openPreview()} deleteIcon={<VisibilityIcon />} />
                <Typography>{formatDate()}</Typography>
                <Button variant="contained" disabled={!pageChange} onClick={onCancel} className={classes.cancelBtn}
                    color="primary">{t('exit-without-saving', 'Avbryt')}</Button>
                {(saved && !pageChange)
                    ? (
                        <div className={classes.saved}>
                            <Typography>Lagret</Typography>
                            <CheckCircle />
                        </div>
                    )
                    : <SaveActions disabled={!pageChange} status={pageRef.current.status} onSave={onSave}/>
                }
            </section>
            <section className={classes.finder}>
                <MediaLib onMediaSelected={onMediaSelected} onMediaLibClose={onMediaLibClose} config={mediaLibConfig} hidden={!mediaLibConfig.open} />
                <RelatedArticles onRelatedSelected={onRelatedSelected} onRelatedClose={onRelatedClose} relatedConfig={relatedConfig} hidden={!relatedConfig.open} />
            </section>
            <Form
                className={classes.form}
                schema={schema}
                uiSchema={uiSchema}
                formData={pageRef.current}
                formContext={{
                    pageType: data.page_type,
                    //owner: user.selectedOwner.abbr,
                    mediaLib: {
                        isOpen: mediaLibConfig && mediaLibConfig.open,
                        open: onMediaLibOpen,
                        remove: onMediaLibRemoveIndex,
                        reorder: onMediaLibReorder
                    },
                    related: {
                        isOpen: relatedConfig && relatedConfig.open,
                        open: onRelatedOpen,
                        remove: onMediaLibRemoveIndex,
                        reorder: onMediaLibReorder
                    },
                    onAddPlace: onAddPlace,
                    setFocus: setFocus,
                    onAnchorMode: onAnchorMode
                }}
                // onCancel={this.onCancel}
                // onSubmit={this.onSubmit}
                // onUpload={this.onUpload}
                onChange={onFormChanged}
                onFocus={onFocus}
                children={true}
                // validate={validate}
                FieldTemplate={NoWrapperFieldTemplate}
                // submitOnEnter
                // activityIndicatorEnabled
            />
            <div id="preview"
                 className={`${classes.preview}${data.page_type === 'owner' ? '' : ' article'}${!!anchorMode ? ' anchorMode' : ''}`}>
                <div className="body">
                    <Preview data={pageRef.current} focusId={focusedId} onPreviewClick={!!!anchorMode && onPreviewClick}
                             anchorObj={!!anchorMode && anchorMode}/>
                </div>
            </div>
        </article>
    );
});
