import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { IconButton, Link, List, ListItem, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { ConfirmDialog } from './ConfirmDialog';

const useStyles = makeStyles(({ spacing }) => ({
    actions: {
        width: '50px',
        height: '100%',
        paddingLeft: spacing(1),
        paddingRight: spacing(1)
    }
}));

export const ArticleListActions = ({ onDelete, openPreview, usedIn }) => {
    const [confirmOpen, setConfirmOpen] = useState(false),
        { t } = useTranslation(['dm.common'], { useSuspense: false }),
        classes = useStyles();

    return (
        <div className={classes.actions}>
            <IconButton aria-label="preview" onClick={openPreview}>
                <VisibilityIcon fontSize='small' />
            </IconButton>
            <IconButton aria-label="delete" onClick={() => setConfirmOpen(true)}>
                <DeleteIcon fontSize='small' />
            </IconButton>
            {usedIn && usedIn.length
                ? (
                    <ConfirmDialog
                        title="Det finnes lenker til dette innholdet!"
                        cancelLabel="Ok"
                        open={confirmOpen}
                        setOpen={setConfirmOpen}
                        onConfirm={onDelete}
                    >
                        <Typography>Vennligst fjern referanse i disse artiklene før du sletter:</Typography>
                        <List disablePadding={true}>
                            {usedIn.map(ref => (
                                <ListItem disableGutters={true} dense={true}>
                                    <Link key={ref.page_id} href={`/article/edit/${ref.page_id}`} target="_blank" rel="noreferrer">
                                        {ref.title}
                                    </Link>
                                </ListItem>
                            ))}
                        </List>
                    </ConfirmDialog>
                ) : (
                    <ConfirmDialog
                        title={t('delete-confirm_title', 'Slett artikkel')}
                        confirmLabel={t('delete-confirm_yes', 'Ja')}
                        cancelLabel={t('delete-confirm_no', 'Nei')}
                        open={confirmOpen}
                        setOpen={setConfirmOpen}
                        onConfirm={onDelete}
                    >
                        {t('delete-confirm_message', 'Er du sikker?')}
                    </ConfirmDialog>
                )}
        </div>
    );
};
