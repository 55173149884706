import React, { Fragment, useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Form from '@rjsf/material-ui';
import { Box, Button, Dialog, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteForever';

import schema from '../Article/schema/resource/ResourceSchema';
import { requestFilter, addResource, updateResource, deleteResource } from '../redux/actions/filter';
import { ConfirmDialog } from '../Article/ArticleListActions/ConfirmDialog';

const useStyles = makeStyles(({ spacing }) => ({
    category: {
        display: 'flex',
        flexFlow: 'wrap',
        margin: '0 1em',
        '& .select': {
            flex: '1 1 47%',
            '&:first-child': {
                margin: '0 1em 0 0',
            }
        },
        '& .list': {
            flex: '1 0 100%',
            '& ul': {
                marginLeft: spacing(2)
            }
        }
    },
    dialog: {
        '& button:first-of-type': {
            marginRight: '1em'
        }
    },
    addBtn: {
        marginTop: '1em',
        marginLeft: '1em',
        marginBottom: '1em',
    }
}));

export default function CategoryEditor({ match }) {
    const dispatch = useDispatch(),
        classes = useStyles(),
        { user } = useSelector(state => state.header),
        { category_data, updated } = useSelector(state => state.category),
        [selected, setSelected] = useState(false),
        [confirmOpen, setConfirmOpen] = useState(false);

    useEffect(() => {
        dispatch(requestFilter('category'));
    }, [dispatch]);

    const onSave = form => {
        dispatch(updateResource(user, 'category', form.formData));
        console.log(`update resource with id ${form.formData.id}. New value: ${form.formData.title}`);
        setSelected(false);
    };

    const onDeleteOpen = c => {
        setConfirmOpen(true);
        setSelected(c);
    };

    const onDeleteConfirm = () => {
        selected && dispatch(deleteResource('category', selected.id));
        setConfirmOpen(false);
        setSelected(false);
    };

    const onDeleteCancel = () => {
        setConfirmOpen(false);
        setSelected(false);
    };

    const onAdd = form => {
        dispatch(addResource(user, 'category', form.formData));
        setSelected(false);
    };

    const renderList = (list, pid) => {
        return (
            <List key={pid && `parent-${pid}`} className="list">
                {list.map(c => {
                    return (
                        <Fragment key={c.id}>
                        <ListItem onClick={() => setSelected({ ...c, source: pid })}>
                            <ListItemIcon>
                                <EditIcon />
                            </ListItemIcon>
                            <ListItemText primary={c.title} />
                            <ListItemIcon onClick={() => onDeleteOpen(c)}>
                                <DeleteIcon />
                            </ListItemIcon>
                        </ListItem>
                        {(c.children && c.children.length)
                            ? renderList(c.children, c.id)
                            : null}
                        </Fragment>
                    )
                })}
            </List>
        );
    };

    return (
        <div className={classes.category}>
            <Button className={classes.addBtn} variant="contained" color="primary" onClick={() => setSelected('new')}>
                Opprett nytt tema
            </Button>
            {category_data && renderList(category_data)}
            <Dialog
                open={!!selected}
                className={classes.dialog}
            >
                {selected && (
                    <Box m={3}>
                        <Form
                            schema={schema['category']}
                            formData={selected}
                            onSubmit={selected === 'new' ? onAdd : onSave}
                        >
                            <Button type="submit" variant="contained" color="primary" className={classes.button}>Lagre</Button>
                            <Button type="submit" variant="contained" color="secondary" className={classes.button} onClick={() => setSelected(null)}>Avbryt</Button>
                        </Form>
                    </Box>
                )}
            </Dialog>
            <ConfirmDialog
                title="Slett tema"
                confirmLabel="Ja"
                cancelLabel="Nei"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={onDeleteConfirm}
                onCancel={onDeleteCancel}
            >
                Er du sikker?
            </ConfirmDialog>
        </div>
    );
}